<template>
    <div class="pc-container">
        <BACK />
        <div id="productBox" class="product-box">
            <div class="img-box">
                <img class="img" :src="details.image" />
            </div>
            <div class="right">
                <div class="name">{{details.title}}</div>
                <div class="progress-box">
                    <el-progress :percentage="(parseFloat(details.has_num) / parseFloat(details.open_num)) * 100" :show-text="false" :stroke-width="13"
                        define-back-color="#E4E7ED" color="#F08834"></el-progress>
                    <span class="progress-text">{{((parseFloat(details.has_num) / parseFloat(details.open_num)) * 100).toFixed(2)}}%</span>
                </div>
                <div class="join-num">{{$t('Finance.JoinNum', {joinNum: details.buied})}}</div>
                <div class="info-item">
                    <div class="label">{{$t('Finance.ReferenceAnnualized')}}</div>
                    <div class="value rate">
                        {{(365 * parseFloat(details.rate)).toFixed(2)}}%
                        <div class="tag">{{$t('Finance.DailyRate')}}: {{parseFloat(details.rate)}}%</div>
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Finance.StartingTime')}}</div>
                    <div class="value">{{details.start_at}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Finance.LockPeriod')}}</div>
                    <div class="value">{{details.day}}{{$t('Finance.day')}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Finance.RebateMethod')}}</div>
                    <div class="value">{{details.type == 1 ? $t('Finance.RebateMethod1') : $t('Finance.RebateMethod2')}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Finance.RemainingPosition')}}</div>
                    <div class="value">{{(parseFloat(details.open_num) - parseFloat(details.has_num)).toFixed(8)}}USDT</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Finance.LockPosition')}}</div>
                    <el-slider v-model="num" :marks="marks" :step="step" :min="0" :max="max" show-stops></el-slider>
                </div>
                <div class="join-btn" @click="join">{{$t('Finance.JoinNow')}}</div>
                <div class="other-info">
                    <div class="label">{{$t('Finance.EndOfDistance')}}</div>
                    <div class="time-box">
                  		<div class="time-item">
                            <div class="time-value">{{countdown[0]}}</div>
                   			<div class="time-label">{{$t('Finance.day')}}</div>
                        </div>
                        <div class="time-item">
                            <div class="time-value">{{countdown[1]}}</div>
                            <div class="time-label">{{$t('Finance.hour')}}</div>
                        </div>
                        <div class="time-item">
                		    <div class="time-value">{{countdown[2]}}</div>
                            <div class="time-label">{{$t('Finance.minute')}}</div>
                        </div>
                        <div class="time-item">
                            <div class="time-value">{{countdown[3]}}</div>
                            <div class="time-label">{{$t('Finance.second')}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="divide-line"></div>
        <div class="product-specification">
            <div class="title">{{$t('Finance.ProductSpecification')}}</div>
            <div class="p-table">
                <div class="tr">
                    <div class="th">{{$t('Finance.LockedCurrency')}}</div>
                    <div class="th">{{$t('Finance.ReferenceAnnualized')}}（{{$t('Finance.DailyRate')}}）</div>
                    <div class="th">{{$t('Finance.LockPeriod')}}（{{$t('Finance.day')}}）</div>
                    <div class="th">{{$t('Finance.SubscriptionAmount')}}（{{$t('Finance.MinAndMax')}}）</div>
                    <div class="th">{{$t('Finance.TotalPosition')}}（USDT）</div>
                </div>
                <div class="tr">
                    <div class="td">USDT</div>
                    <div class="td">{{(365 * parseFloat(details.rate)).toFixed(2)}}% ({{parseFloat(details.rate)}}%)</div>
                    <div class="td">{{details.day}}</div>
                    <div class="td">{{details.min_num}}/{{details.max_num}}USDT</div>
                    <div class="td">{{details.open_num}}</div>
                </div>
            </div>
        </div>
        <div class="trading-rules">
            <div class="title">{{$t('Finance.TradingRules')}}</div>
            <div v-html="rule" class="content"></div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            BACK: () => import('@/components/back.vue')
        },
        data() {
            return {
                productId: '',
                details: {
                    has_num: 0,
                    open_num: 1
                },
                num: 0,
                marks: {
                    0: '0',
                },
                step: 0,
                max: 0,
                timer: null,
                countdown: ['00','00','00','00'],
                rule: ''
            }
        },
        created() {
            this.productId = this.$route.query.id || ''
            this.getDetails()
            this.getRule()
        },
        destroyed() {
            // console.log('destroyed')
            clearInterval(this.timer)
        },
        methods: {
            getDetails() {
                this.$api.financeDetail({
                    id: this.productId
                }).then(res => {
                    this.details = res.data
                    let steps = this.details.step.split(',')
                    steps.forEach((item, index) => {
                    	this.marks[item] = item
                        if(index == 0) {
                            this.step = Number(item)
                        }
                    	if(index == (steps.length - 1)) {
                            this.max = Number(item)
                        }
                    })
                    
                    this.timer = setInterval(() => {
                        let nowTime = new Date().format("yyyy-MM-dd hh:mm:ss")
                        this.countdown = this.calCountdown(nowTime, this.details.end_at)
                    },1000)
                })
            },
            
            getRule() {
                this.$api.setting_lang({
                    slug: 'financeInfo'
                }, 300000).then(res => {
                    if(res.data) {
                        this.rule = res.data
                    }
                })
            },
            
            calCountdown(time1,time2) {
                let Time1 = Date.parse(time1)
                let Time2 = Date.parse(time2)
                let timestrap = (Time2 - Time1) / 1000 //开始得出时间差,然后计算
                //得到天 格式化成前缀加零的样式
                let d = parseInt(timestrap / 60 / 60 / 24)
                d = d < 10 ? '0' + d : d
                //得到小时 格式化成前缀加零的样式
                let h = parseInt(timestrap / 60 / 60 % 24)
                h = h < 10 ? '0' + h : h
                //得到分钟 格式化成前缀加零的样式
                let m = parseInt(timestrap / 60 % 60)
                m = m < 10 ? '0' + m : m
                //得到秒 格式化成前缀加零的样式
                let s = parseInt(timestrap % 60)
                s = s < 10 ? '0' + s : s
            
                return [d, h, m, s]
            },
            
            join() {
                this.$confirm(this.$t('Finance.BuyProductTips'), '', {
                    customClass: "prompt-box",
                    confirmButtonText: this.$t('Common.Confirm'),
                    cancelButtonText: this.$t('Common.Cancel'),
                    confirmButtonClass: 'prompt-confirm-btn',
                    cancelButtonClass: 'prompt-cancel-btn',
                    showClose: false,
                    closeOnClickModal: false,
                    type: 'warning'
                }).then(() => {
                    let loading = this.$loading.service({
                        target: '#productBox',
                        text: this.$t('Common.Loading')
                    })
                    this.$api.financeCreate({
                        finance_id: this.productId,
                        money: this.num
                    }).then(res => {
                        loading.close()
                        this.$message({
                            message: res.message,
                            type: 'success',
                            duration: 1500
                        })
                    }).catch(err => {
                        loading.close()
                    })
                }).catch(() => {
                    
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .pc-container {
        width: 100%;
        box-sizing: border-box;
        padding: 25px $box-padding 40px;
        background-color: #f6f6f6;

        .product-box {
            display: flex;
            width: 100%;
        
            .img-box {
                min-width: 48%;
                max-width: 48%;
                max-height: 48%;
                margin-right: 25px;
                overflow: hidden;
        
                .img {
                    width: 100%;
                }
            }
        
            .right {
                width: 100%;
        
                .name {
                    width: 100%;
                    margin-bottom: 14px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #000000;
                    word-wrap: break-word;
                }
        
                .progress-box {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 14px;
        
                    /deep/ .el-progress {
                        width: 100%;
                    }
        
                    /deep/ .el-progress-bar {
                        padding: 0;
                        margin: 0;
                    }
        
                    .progress-text {
                        margin-left: 12px;
                        font-size: 15px;
                        color: #F08834;
                        white-space: nowrap;
                    }
                }
        
                .join-num {
                    width: 100%;
                    margin-bottom: 12px;
                    font-size: 14px;
                    color: #F08834;
                    word-wrap: break-word;
                }
        
                .info-item {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 12px;
        
                    .label {
                        margin-right: 12px;
                        font-size: 14px;
                        color: #6D6F7E;
                        white-space: nowrap;
                    }
        
                    .value {
                        max-width: 100%;
                        font-size: 14px;
                        color: #000000;
                        word-break: break-all;
                    }
        
                    .rate {
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        font-weight: bold;
                        color: #F08834;
        
                        .tag {
                            height: 24px;
                            line-height: 24px;
                            box-sizing: border-box;
                            padding: 0 8px;
                            margin-left: 10px;
                            border-radius: 2px;
                            font-size: 12px;
                            font-weight: normal;
                            color: #F08834;
                            text-align: center;
                            white-space: nowrap;
                            background-color: #E4E7ED;
                        }
                    }
        
                    /deep/ .el-slider {
                        width: 80%;
                        margin-left: 10px;
                    }
        
                    /deep/ .el-slider__bar {
                        background-color: #F08834;
                    }
        
                    /deep/ .el-slider__marks-text {
                        white-space: nowrap;
                    }
                }
        
                .join-btn {
                    display: inline-block;
                    height: 38px;
                    line-height: 38px;
                    box-sizing: border-box;
                    padding: 0 32px;
                    margin: 25px 0 16px;
                    border-radius: 2px;
                    font-size: 14px;
                    color: #FFFFFF;
                    text-align: center;
                    white-space: nowrap;
                    background-color: #F08834;
                    cursor: pointer;
                }
        
                .other-info {
                    width: 100%;
        
                    .label {
                        width: 100%;
                        margin-bottom: 12px;
                        font-size: 14px;
                        color: #6D6F7E;
                        word-wrap: break-word;
                    }
        
                    .time-box {
                        display: flex;
                        align-items: center;
                        width: 100%;
        
                        .time-item {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
        
                            &:not(:last-child) {
                                margin-right: 10px;
                            }
        
                            .time-value {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 36px;
                                height: 38px;
                                margin-bottom: 4px;
                                border-radius: 2px;
                                font-size: 14px;
                                color: #000000;
                                white-space: nowrap;
                                background-color: #e3e3e3;
                            }
        
                            .time-label {
                                font-size: 12px;
                                color: #6D6F7E;
                                text-align: center;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
        
        .divide-line {
            width: 100%;
            height: 1px;
            margin: 40px 0;
            background-color: #E9E9E9;
        }
        
        .product-specification {
            width: 100%;
            box-sizing: border-box;
            padding: 32px 32px 40px;
            margin-bottom: 24px;
            border-radius: 2px;
            background-color: #FFFFFF;
        
            .title {
                width: 100%;
                margin-bottom: 24px;
                font-size: 18px;
                font-weight: bold;
                color: #000000;
                word-wrap: break-word;
            }
        
            .p-table {
                width: 100%;
                box-sizing: border-box;
                padding: 0 24px;
        
                .tr {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    cursor: default;
        
                    &:first-child {
                        background-color: #F3F5FF;
                    }
        
                    .th {
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        line-height: 120%;
                        box-sizing: border-box;
                        padding: 18px 12px;
                        font-size: 15px;
                        color: #6D6F7E;
                        text-align: center;
                        word-wrap: break-word;
                    }
        
                    .td {
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        box-sizing: border-box;
                        padding: 18px 12px;
                        font-size: 16px;
                        color: #000000;
                        text-align: center;
                        word-break: break-all;
                    }
                }
            }
        }
        
        .trading-rules {
            width: 100%;
            box-sizing: border-box;
            padding: 32px 32px 44px;
            border-radius: 2px;
            background-color: #FFFFFF;
        
            .title {
                width: 100%;
                margin-bottom: 24px;
                font-size: 18px;
                font-weight: bold;
                color: #000000;
                word-wrap: break-word;
            }
        
            .content {
                width: 100%;
                box-sizing: border-box;
                padding: 0 24px;
                word-wrap: break-word;
            }
        }
    }
</style>
